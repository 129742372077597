export const FormatDate = (dateTime) => {
  const date = new Date(dateTime);
  const formattedDate = date.toLocaleDateString("en-CA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  return formattedDate;
};

export const formatDateTime = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
};

export const getDefaultFromDate = () => {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  return date;
};
