import axiosInstance from "../configs/axios.conf";

async function SearchTaxCode(taxCode) {
  try {
    const response = await axiosInstance.get(
      `m-invoice/company-info/${taxCode}`
    );

    return response.data.content;
  } catch (error) {
    console.error("Error:", error.message);
    return { error: error.message };
  }
}

export default SearchTaxCode;
