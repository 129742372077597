import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useLocation, Link, useNavigate } from "react-router-dom";
import {
  clearAuthKiotviet,
  clearSelectedOrderList,
  setRemainingTimeToken,
} from "../../redux/createInvoiceSlice";

const breadcrumbLabels = {
  "/order-list": "Danh sách hoá đơn trên Kiotviet",
  "/tu-dong-dang-nhap": "Đăng nhập CRM",
  "/chuyen-chu-ky-so": "Chuyển chữ ký số",
  "/services": "Dịch vụ",
  "/branchreport": "Báo cáo chi nhánh",
  "/systemreport": "Báo cáo toàn hệ thống",
  "/create-invoices": "Xuất hoá đơn",
  "/cau-hinh-tai-khoan": "Cấu hình thông tin tài khoản m-invoice",
  "/huong-dan-su-dung": "Hướng dẫn sử dụng",
  "/tao-hoa-don-tu-dong": "Xuất hoá đơn tự động",
  // Thêm các đường dẫn và nhãn khác nếu cần thiết
};

const Breadcrumbs = () => {
  const location = useLocation();
  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment !== "");

  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const userInfo = useSelector((state) => state.createInvoice.userInfo);
  const remainingTimeToken = useSelector(
    (state) => state.createInvoice.remainingTimeToken
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (userInfo) {
      const currentTime = Math.floor(Date.now() / 1000);
      const remainingSeconds = userInfo.exp - currentTime - 5 * 24 * 60 * 60; // Trừ thêm 5 ngày
      if (remainingSeconds <= 0) {
        localStorage.removeItem("cookie-kiotviet");
        dispatch(clearSelectedOrderList());
        dispatch(clearAuthKiotviet());
        navigate("/");
      } else {
        dispatch(setRemainingTimeToken(remainingSeconds));
      }
    }
  }, [userInfo]);

  const formatRemainingTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    return `${days} ngày ${hours % 24} giờ`;
  };

  // Tạo một mảng chứa các phần tử breadcrumb
  const breadcrumbs = [];
  let breadcrumbPath = "";

  for (let i = 0; i < pathSegments.length; i++) {
    breadcrumbPath += `/${pathSegments[i]}`;
    breadcrumbs.push({ path: breadcrumbPath, label: pathSegments[i] });
  }

  // Xử lý khi bấm vào biểu tượng và điều hướng về /dashboard
  const handleIconClick = () => {
    navigate("/order-list");
  };

  return (
    <div
      style={{
        backgroundColor: "#FAFAFA",
        // position: "fixed",
        color: "#0069b4",
        marginTop: "4rem",
        display: "flex",
        fontWeight: "350",
        fontSize: "13px",
        alignItems: "center",
        padding: "1rem",
        // zIndex: 2,
        justifyContent: "space-between",
      }}
      className="px-2"
    >
      {breadcrumbs.map((breadcrumb, index) => (
        <React.Fragment key={breadcrumb.path}>
          {index > 0 && <i className="fa-solid fa-angle-right"></i>}
          {index === 0 ? (
            <div className="flex cursor-pointer" onClick={handleIconClick}>
              {breadcrumb.path === "/" ? (
                <i className="fa-solid fa-house-chimney"></i>
              ) : (
                <i className="fa-solid fa-house-chimney"></i>
              )}

              {breadcrumb.path === "/" ? null : (
                <i
                  className="fa-solid fa-angle-right"
                  style={{ padding: " 0 0.4rem" }}
                ></i>
              )}
              {breadcrumb.path === "/" ? (
                <i
                  className="fa-solid fa-angle-right"
                  style={{ padding: " 0 0.4rem" }}
                ></i>
              ) : (
                <p className="font-semibold">
                  {breadcrumbLabels[breadcrumb.path] || breadcrumb.label}
                </p>
              )}
            </div>
          ) : (
            <Link to={breadcrumb.path}>
              <p className="font-semibold">
                {breadcrumbLabels[breadcrumb.path] || breadcrumb.label}
              </p>
            </Link>
          )}
        </React.Fragment>
      ))}

      <div className="flex flex-column gap-2">
        <p
          style={{
            display: "block",
            color: "#0069b",
            fontWeight: "700",

            textAlign: "end",
            paddingRight: "3px",
            fontSize: "12px",
          }}
        >
          {"Tên: " + userInfo?.kvugvname}
        </p>
        <p className="text-xs font-semibold">
          Thời hạn phiên đăng nhập: {formatRemainingTime(remainingTimeToken)}
        </p>
      </div>
    </div>
  );
};
export default Breadcrumbs;
