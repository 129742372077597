import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCookie, setUserInfo } from "../redux/createInvoiceSlice";
import { jwtDecode } from "jwt-decode";
import ToastNotify from "../components/ToastNotify/ToastNotify";
import { styleError } from "../components/ToastNotify/ToastNotifyStyle";

const Login = () => {
  const [cookieInfo, setCookieInfo] = useState("");
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const cookieLocal = localStorage.getItem("cookie-kiotviet");

  useEffect(() => {
    if (cookieLocal) {
      const tokenMatch = cookieLocal.match(/ss-tok=([^;]+)/);
      if (!tokenMatch) {
        toast.error(
          <ToastNotify status={-1} message={"Cookie không hợp lệ"} />,
          { style: styleError }
        );
      } else {
        const token = cookieLocal.match(/ss-tok=([^;]+)/)[1];
        const decoded = jwtDecode(token);
        const currentTime = Math.floor(Date.now() / 1000);
        if (decoded.exp > currentTime) {
          dispatch(setUserInfo(decoded));
          navigate("/order-list");
        } else {
          toast.error(
            <ToastNotify status={-1} message={"Phiên đăng nhập hết hạn"} />,
            { style: styleError }
          );
        }
      }
    }
  }, [cookieLocal]);

  const handleLogin = async () => {
    if (cookieInfo) {
      try {
        const tokenMatch = cookieInfo.match(/ss-tok=([^;]+)/);
        if (tokenMatch) {
          const token = cookieInfo.match(/ss-tok=([^;]+)/)[1];
          const decoded = jwtDecode(token);
          const currentTime = Math.floor(Date.now() / 1000);
          if (decoded.exp > currentTime) {
            localStorage.setItem("cookie-kiotviet", cookieInfo);
            dispatch(setCookie(cookieInfo));
            navigate("/order-list");
          } else {
            toast.error(
              <ToastNotify status={-1} message={"Phiên đăng nhập hết hạn"} />,
              { style: styleError }
            );
          }
        } else {
          toast.error(
            <ToastNotify status={-1} message={"Cookie không hợp lệ"} />,
            { style: styleError }
          );
        }
      } catch (err) {
        // Xử lý lỗi khi đăng nhập
        console.error("Đăng nhập thất bại:", err);
      }
    } else {
      toast.error(
        <ToastNotify status={-1} message={"Vui lòng nhập cookie"} />,
        { style: styleError }
      );
    }
  };

  return (
    <div className="login relative">
      <div className="absolute top-0 right-0">
        <div className="p-2 hover:bg-blue-700 hover:border-round-md hover:border-1 bg-blue-500 m-2 border-round-md cursor-pointer">
          <Link className="link" to="/huong-dan">
            <span
              style={{ paddingRight: "5px", color: "white" }}
              className="fa-solid fa-book"
            ></span>
            <span className="text-sm font-semibold text-white">
              Hướng dẫn sử dụng
            </span>
          </Link>
        </div>
      </div>
      <div className="left-row"></div>
      <div className="right-row">
        <div className="form-auth">
          <div style={{ textAlign: "center" }}>
            <img
              src={require("../assets/logo-minvoice.png")}
              alt="logo"
              width={"164px"}
              height={"115px"}
            ></img>
            <p
              style={{
                fontWeight: 500,
                color: "#99321E",
                fontSize: "23px",
                marginBottom: "30px",
              }}
            >
              Đồng bộ hoá đơn từ Kiotviet
            </p>
          </div>

          <form className="form-login">
            <label className="block  mb-2 fz-15" htmlFor="cookie">
              Cấu hình cookie
            </label>
            <InputText
              onChange={(e) => setCookieInfo(e.target.value)}
              className="input-login mb-3"
            ></InputText>
          </form>
          <div className="flex justify-content-center">
            <Button
              type="submit"
              className="mt-2 p-2 flex gap-2 align-items-center justify-content-center w-full"
              onClick={handleLogin}
            >
              <i className="fa-regular fa-user"></i>
              Xác nhận
            </Button>
          </div>
          <ToastContainer
            autoClose={2000}
            hideProgressBar
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
