import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Panel } from "primereact/panel";
import "./filterOrder.css";
import { InputText } from "primereact/inputtext";
import { useDispatch, useSelector } from "react-redux";
import GetOrderList from "../../utils/GetOrder";
import {
  setInvoiceList,
  setIsLoadingFilter,
  setOrderList,
  setTotalRecords,
} from "../../redux/getOrderListSlice";
import Loading from "../Loading/Loading";
import getListInvoiceFromMInvoice from "../../utils/getInvoiceListFromMInvoice";
import { getDefaultFromDate } from "../../utils/formatDate";
import { setIsAutoSyncCreateInvoices } from "../../redux/createInvoiceSlice";
import { useNavigate } from "react-router-dom";

const FilterOrder = ({ isRefresh }) => {
  const [showFilter, setShowFilter] = useState(false);
  const [fromDate, setFromDate] = useState(getDefaultFromDate());
  const [toDate, setToDate] = useState(getDefaultFromDate());
  const [orderId, setOrderId] = useState("");
  const dispatch = useDispatch();
  const cookie = useSelector((state) => state.createInvoice.cookie);
  const token = useSelector((state) => state.createInvoice.token);
  const taxCode = useSelector((state) => state.createInvoice.taxCode);
  const selectedSymbol = useSelector(
    (state) => state.createInvoice.selectedSymbol
  );
  const companyInfo = useSelector((state) => state.createInvoice.companyInfo);
  const isAutoSyncCreateInvoice = useSelector(
    (state) => state.createInvoice.isAutoSyncCreateInvoice
  );
  const totalRecords = useSelector((state) => state.getOrderList.totalRecords);
  const invoiceList = useSelector((state) => state.getOrderList.invoiceList);
  const orderList = useSelector((state) => state.getOrderList.orderList);
  const itemPerPage = useSelector((state) => state.getOrderList.itemPerPage);
  const currentPage = useSelector((state) => state.getOrderList.currentPage);
  const isLoadingFilter = useSelector(
    (state) => state.getOrderList.isLoadingFilter
  );
  const [isResetFilter, setIsResetFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleGetOrderList = async (itemPerPage, currentPage) => {
    dispatch(setIsLoadingFilter(true));
    try {
      const dataOrderList = await GetOrderList(
        cookie,
        fromDate,
        toDate,
        orderId,
        itemPerPage,
        currentPage
      );

      if (dataOrderList.content.data.Data) {
        const updatedOrderList = dataOrderList.content.data.Data.slice(1);
        dispatch(setOrderList(updatedOrderList));
        dispatch(setTotalRecords(dataOrderList.content.data.Total));
      }
    } catch (err) {
      console.error("Lỗi tải dữ liệu:", err);
    }
    setIsLoadingFilter(false);
  };

  const handleGetInvoiceList = async () => {
    dispatch(setIsLoadingFilter(true));
    const data = {
      fromDate: fromDate,
      toDate: toDate,
      khieu: selectedSymbol,
      token: token,
      taxCode: taxCode,
    };
    try {
      const response = await getListInvoiceFromMInvoice(data);
      if (response.content.data.code === "00") {
        dispatch(setInvoiceList(response.content.data.data));
      }
    } catch (error) {
      console.error("Lỗi khi tải dữ liệu:", error);
      throw error;
    }
    setIsLoading(false);
    dispatch(setIsLoadingFilter(false));
  };

  useEffect(() => {
    if (orderList.length > 0 && invoiceList.length > 0) {
      const updateNewOrderList = [...orderList];

      for (let invoice of invoiceList) {
        let isExistingId = updateNewOrderList.findIndex(
          (order) => order.Code === invoice.so_benh_an
        );

        if (isExistingId !== -1) {
          updateNewOrderList[isExistingId] = {
            ...updateNewOrderList[isExistingId],
            isCreatedInvoice: true,
          };
        }
      }
      dispatch(setOrderList(updateNewOrderList));
      dispatch(setIsLoadingFilter(false));
      dispatch(setIsAutoSyncCreateInvoices(false));
    } else {
      dispatch(setIsLoadingFilter(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    invoiceList,
    companyInfo,
    fromDate,
    toDate,
    taxCode,
    selectedSymbol,
    orderId,
    itemPerPage,
    currentPage,
    isAutoSyncCreateInvoice,
    isRefresh,
  ]);

  useEffect(() => {
    if (companyInfo && selectedSymbol) {
      dispatch(setIsLoadingFilter(true));
      handleGetInvoiceList();
    } else {
      navigate("/cau-hinh-tai-khoan");
    }
  }, [
    companyInfo,
    itemPerPage,
    currentPage,
    isAutoSyncCreateInvoice,
    selectedSymbol,
    isRefresh,
  ]);

  useEffect(() => {
    if (cookie) {
      handleGetOrderList(itemPerPage, currentPage);
    }
  }, [cookie, itemPerPage, currentPage, isAutoSyncCreateInvoice, isRefresh]);

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleFilter = () => {
    setIsLoading(true);
    dispatch(setIsLoadingFilter(true));
    handleGetOrderList(itemPerPage, currentPage);
    if (companyInfo) {
      handleGetInvoiceList();
    }
  };

  const handleResetFilter = () => {
    dispatch(setIsLoadingFilter(true));
    setFromDate(getDefaultFromDate());
    setToDate(getDefaultFromDate());
    setOrderId("");
    setIsResetFilter(true);
    if (companyInfo) {
      handleGetInvoiceList();
    }
  };

  useEffect(() => {
    if (isResetFilter) {
      handleGetOrderList(itemPerPage, currentPage);
      setIsResetFilter(false);
    }
  }, [isResetFilter]);

  return (
    <div className="py-2">
      <Button
        label="Lọc đơn hàng"
        icon="pi pi-filter"
        onClick={toggleFilter}
        className="p-2 bg-white text-blue-500 text-sm border-1 border-blue-500 hover:bg-blue-100"
      />
      {showFilter && (
        <Panel>
          <div className="p-4 flex flex-column gap-2">
            <div className="flex justify-content-start align-items-center gap-5 ">
              <div className="p-2 flex gap-2 align-items-center">
                <label htmlFor="fromDate" className="text-sm font-base">
                  Từ ngày
                </label>
                <Calendar
                  id="fromDate"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.value)}
                  showIcon
                  className="custom-input"
                />
              </div>
              <div className="p-2 flex align-items-center gap-2">
                <label htmlFor="toDate" className="text-sm font-base">
                  Đến ngày
                </label>
                <Calendar
                  id="toDate"
                  value={toDate}
                  onChange={(e) => setToDate(e.value)}
                  showIcon
                  className="custom-input"
                  minDate={fromDate}
                />
              </div>
            </div>
            <div className="p-2 flex align-items-center gap-2">
              <label htmlFor="orderId" className="text-sm font-base">
                Mã đơn hàng
              </label>
              <InputText
                value={orderId}
                onChange={(e) => setOrderId(e.target.value)}
                className="px-2 py-1 text-sm"
              />
            </div>
            <div>
              <div className="flex justify-content-start align-items-center gap-2">
                <Button
                  icon="pi pi-filter"
                  onClick={handleFilter}
                  className="p-2 bg-blue-500 text-white text-xs border-1 border-blue-500 hover:bg-blue-600 custom-icon-size"
                >
                  {isLoading ? <Loading /> : "Lọc"}
                </Button>
                <Button
                  onClick={handleResetFilter}
                  className="p-2 bg-red-500 text-white text-xs border-1 border-red-500 hover:bg-red-600 custom-icon-size"
                >
                  {isResetFilter ? <Loading /> : "Xoá lọc"}
                </Button>
              </div>
            </div>
            {totalRecords && (
              <div className="">
                <p className="text-xs text-blue-500 font-semibold">
                  Tổng {totalRecords} đơn hàng
                </p>
              </div>
            )}
          </div>
        </Panel>
      )}
    </div>
  );
};

export default FilterOrder;
