import axiosInstance from "../configs/axios.conf";

const LoginToMInvoice = async (username, password, taxCode) => {
  const data = {
    username: username,
    password: password,
    domain: taxCode,
  };
  try {
    const response = await axiosInstance.post("/kiotviet/login-minvoice", data);

    return response.data;
  } catch (error) {
    console.error("Lỗi khi đăng nhập:", error);
    throw error;
  }
};

export default LoginToMInvoice;
