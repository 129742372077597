// import axios from "axios";
import axiosInstance from "../configs/axios.conf";

const CreateInvoiceAPI = async (taxCode, token, dataCreateInvoice) => {
  const data = {
    taxCode: taxCode,
    token: token,
    dataCreateInvoice: dataCreateInvoice,
  };

  try {
    const response = await axiosInstance.post("m-invoice/create-invoice", data);

    return response.data;
  } catch (error) {
    console.error("Lỗi khi xuất hóa đơn:", error);
    throw error;
  }
};

export default CreateInvoiceAPI;
