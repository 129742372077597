import axiosInstance from "../configs/axios.conf";

const CancelAutoCreateInvoice = async (taxCode) => {
  try {
    const response = await axiosInstance.get(
      `/auto-create-invoice/stop-auto-create-invoice?user_id=${taxCode}`
    );

    return response.data;
  } catch (error) {
    console.error("Cancel error:", error);
    throw error;
  }
};

export default CancelAutoCreateInvoice;
