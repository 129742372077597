import { Dropdown } from "primereact/dropdown";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedSymbol,
  setSymbolList,
} from "../../redux/createInvoiceSlice";
import GetSymbolList from "../../utils/GetSymbolList";
import "./ChooseInvoiceSymbol.css";

const ChooseInvoiceSymbol = () => {
  const token = useSelector((state) => state.createInvoice.token);
  const taxCode = useSelector((state) => state.createInvoice.taxCode);
  const dispatch = useDispatch();
  const symbolList = useSelector((state) => state.createInvoice.symbolList);
  const selectedSymbol = useSelector(
    (state) => state.createInvoice.selectedSymbol
  );

  useEffect(() => {
    if (taxCode && token) {
      handleGetSymbolList(taxCode, token);
    }
  }, [taxCode, token]);

  useEffect(() => {
    if (selectedSymbol) {
      localStorage.setItem("selectedSymbol", selectedSymbol);
    } else {
      const symbol = localStorage.getItem("selectedSymbol");
      if (symbol) {
        dispatch(setSelectedSymbol(symbol));
      }
    }
  }, [selectedSymbol]);

  const handleGetSymbolList = async (taxCode, token) => {
    try {
      const symbolList = await GetSymbolList(taxCode, token);
      if (symbolList.content.data.code === "00") {
        dispatch(setSymbolList(symbolList.content.data.data));
      }
    } catch (error) {
      console.error("Lỗi khi lấy danh sách kí hiệu hóa đơn:", error);
    }
  };

  const customItemTemplate = (option) => {
    return <div className="p-3">{option.khhdon}</div>;
  };

  return (
    <div>
      <div className="mt-3">
        <div className="p-2">
          <Dropdown
            value={selectedSymbol}
            onChange={(e) => dispatch(setSelectedSymbol(e.value))}
            options={symbolList}
            optionLabel="khhdon"
            placeholder="Chọn kí hiệu hoá đơn"
            className="w-full md:w-18rem p-2 custom-dropdown text-sm"
            itemTemplate={customItemTemplate}
            filter
            filterBy="khhdon"
          />
        </div>
      </div>
    </div>
  );
};

export default ChooseInvoiceSymbol;
