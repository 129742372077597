import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import ChooseInvoiceSymbol from "../components/ChooseInvoiceSymbol/ChooseInvoiceSymbol";
import Loading from "../components/Loading/Loading";
import ToastNotify from "../components/ToastNotify/ToastNotify";
import {
  styleError,
  styleSuccess,
} from "../components/ToastNotify/ToastNotifyStyle";
import {
  clearAuth,
  setCompanyInfo,
  setLoginStatus,
  setTaxCode,
  setToken,
  setUserInfo,
} from "../redux/createInvoiceSlice";
import SearchTaxCode from "../utils/getAllDmdv";
import LoginToMInvoice from "../utils/LoginToMInvoiceAPI";
import { jwtDecode } from "jwt-decode";
import { useEffect } from "react";

const AccountConfig = () => {
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const [taxCodeLocal, setTaxCodeLocal] = useState("");
  console.log("🚀 ~ AccountConfig ~ taxCodeLocal:", taxCodeLocal);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const companyInfo = useSelector((state) => state.createInvoice.companyInfo);
  const cookieLocal = localStorage.getItem("cookie-kiotviet");
  const [cookie, setCookie] = useState(cookieLocal || "");

  const handleGetCustomerInfo = async () => {
    const companyInfo = await SearchTaxCode(taxCodeLocal);
    if (companyInfo.data) {
      localStorage.setItem("companyInfo", JSON.stringify(companyInfo.data));
      dispatch(setCompanyInfo(companyInfo.data));
    }
  };

  const handleConfirmAccountInfo = async () => {
    setIsLoadingLogin(true);
    try {
      const dataLogin = await LoginToMInvoice(username, password, taxCodeLocal);
      if (dataLogin.content.data?.token) {
        localStorage.setItem("tokenMInvoice", dataLogin.content.data.token);
        localStorage.setItem("taxCode", taxCodeLocal);
        dispatch(setToken(dataLogin.content.data.token));
        dispatch(setTaxCode(taxCodeLocal));
        dispatch(setLoginStatus(true));
        await handleGetCustomerInfo();
        setIsLoadingLogin(false);
      } else {
        toast.error(
          <ToastNotify
            status={-1}
            message={`Đăng nhập thất bại! Sai mật khẩu hoặc password`}
          />,
          { style: styleError }
        );
        dispatch(setLoginStatus(false));
        setIsLoadingLogin(false);
      }
    } catch (err) {
      toast.error(
        <ToastNotify
          status={-1}
          message={`Đăng nhập thất bại ${err.message}`}
        />,
        { style: styleError }
      );
      setIsLoadingLogin(false);
    }
  };

  const handleExitAccount = () => {
    localStorage.removeItem("companyInfo");
    localStorage.removeItem("tokenMInvoice");
    localStorage.removeItem("taxCode");
    localStorage.removeItem("companyName");
    localStorage.removeItem("selectedSymbol");
    dispatch(clearAuth());
  };

  const [isSameCookie, setIsSameCookie] = useState(false);
  useEffect(() => {
    if (cookieLocal) {
      if (cookieLocal === cookie) {
        setIsSameCookie(true);
      } else {
        setIsSameCookie(false);
        setCookie(cookieLocal);
      }
    }
  }, [cookieLocal]);

  const handleChangeCookie = (e) => {
    const value = e.target.value;
    if (value === cookie) {
      setIsSameCookie(true);
    } else {
      setIsSameCookie(false);
    }
    setCookie(e.target.value);
  };

  const handleUpdateCookie = () => {
    if (cookie) {
      try {
        const tokenMatch = cookie.match(/ss-tok=([^;]+)/);
        if (tokenMatch) {
          const token = cookie.match(/ss-tok=([^;]+)/)[1];
          const decoded = jwtDecode(token);
          const currentTime = Math.floor(Date.now() / 1000);
          if (decoded.exp > currentTime) {
            dispatch(setUserInfo(decoded));
            toast.success(
              <ToastNotify status={1} message={"Cập nhật cookie thành công"} />,
              { style: styleSuccess }
            );
            localStorage.setItem("cookie-kiotviet", cookie);
            dispatch(setCookie(cookie));
          } else {
            toast.error(
              <ToastNotify status={-1} message={"Cookie đã hết hạn"} />,
              { style: styleError }
            );
          }
        } else {
          toast.error(
            <ToastNotify status={-1} message={"Cookie không hợp lệ"} />,
            { style: styleError }
          );
        }
      } catch (err) {
        // Xử lý lỗi khi đăng nhập
        console.error("Đăng nhập thất bại:", err);
      }
    } else {
      toast.error(
        <ToastNotify status={-1} message={"Vui lòng nhập cookie"} />,
        { style: styleError }
      );
    }
  };
  return (
    <div className="p-3 flex flex-column gap-4">
      {!companyInfo && (
        <Card className="p-4" title="Cấu hình thông tin đăng nhập">
          <div className="flex flex-column gap-4 mt-4">
            <div className="flex align-items-center gap-2">
              <label htmlFor="" className="w-2">
                Mã số thuế
              </label>
              <InputText
                type="text"
                id="taxcode"
                className="p-2 w-3"
                onChange={(e) => setTaxCodeLocal(e.target.value)}
              />
            </div>
            <div className="flex align-items-center gap-2">
              <label htmlFor="username" className="w-2">
                Tên đăng nhập
              </label>
              <InputText
                type="text"
                id="username"
                className="p-2 w-3"
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="flex align-items-center gap-2">
              <label htmlFor="password" className="w-2">
                Mật khẩu
              </label>
              <InputText
                type="password"
                id="password"
                className="p-2 w-3"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="flex align-items-center gap-2">
              <Button
                className="py-2 px-3 text-sm font-semibold"
                onClick={handleConfirmAccountInfo}
              >
                {isLoadingLogin ? <Loading /> : "Xác nhận"}
              </Button>
            </div>
          </div>
        </Card>
      )}

      {companyInfo && (
        <div className="p-3 flex flex-column gap-4">
          <Card title={"Thông tin tài khoản"} className="p-4">
            <div className="w-12">
              <div className="flex justify-content-end align-items-center">
                <div
                  className="p-2 bg-blue-500 border-round-md cursor-pointer hover:bg-blue-700"
                  onClick={handleExitAccount}
                >
                  <p className="text-xs text-white font-semibold">
                    Thoát tài khoản
                  </p>
                </div>
              </div>
              <div>
                <div className="flex justify-content-start align-items-center mt-2 gap-2">
                  <p className=" w-1 text-xs font-semibold">Tên DN:</p>
                  <p className=" w-11 text-xs font-semibold">
                    {companyInfo.ten_cty}
                  </p>
                </div>
                <div className="flex justify-content-start align-items-center mt-2 gap-2">
                  <p className="w-1 text-xs font-semibold">MST:</p>
                  <p className="w-11 text-xs font-semibold">
                    {companyInfo.ma_so_thue}
                  </p>
                </div>
                <div className="flex justify-content-start align-items-center mt-2 gap-2">
                  <p className=" w-1 text-xs font-semibold">Địa chỉ:</p>
                  <p className=" w-11 text-xs font-semibold">
                    {companyInfo.dia_chi}
                  </p>
                </div>
              </div>
            </div>
          </Card>
          <Card title={"Chọn kí hiệu hoá đơn"} className="p-4">
            <ChooseInvoiceSymbol />
          </Card>
          <Card title={"Cập nhật cookie"} className="p-4">
            <form className="form-login mt-2">
              {/* <label className="block  mb-2 fz-15" htmlFor="cookie">
                Cookie
              </label> */}
              <InputText
                onChange={(e) => handleChangeCookie(e)}
                className="input-login mb-3"
                value={cookie}
              ></InputText>
            </form>
            <div className="flex justify-content-start">
              <Button
                type="submit"
                className="mt-2 p-2 flex gap-2 align-items-center justify-content-center "
                onClick={handleUpdateCookie}
                disabled={isSameCookie}
              >
                <i className="fa-regular fa-user"></i>
                Xác nhận
              </Button>
            </div>
          </Card>
        </div>
      )}
      <ToastContainer
        autoClose={2000}
        hideProgressBar
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default AccountConfig;
