import { Button } from "primereact/button";
import { Card } from "primereact/card";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConfirmCreateInvoice from "../components/ConfirmCreateInvoice/ConfirmCreateInvoice";
import Loading from "../components/Loading/Loading";
import { setResponseCreateInvoice } from "../redux/createInvoiceSlice";
import CreateInvoiceAPI from "../utils/CreateInvoice";
import { setCurrentPage } from "../redux/getOrderListSlice";

const CreateInvoice = () => {
  const onSelectedOrderList = useSelector(
    (state) => state.createInvoice.onSelectedOrderList
  );
  const token = useSelector((state) => state.createInvoice.token);
  const taxCode = useSelector((state) => state.createInvoice.taxCode);
  const storeDataCreateInvoice = useSelector(
    (state) => state.createInvoice.storeDataCreateInvoice
  );
  const responseCreateInvoice = useSelector(
    (state) => state.createInvoice.responseCreateInvoice
  );
  const [isLoadingCreateInvoice, setIsLoadingCreateInvoice] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (onSelectedOrderList && onSelectedOrderList.length === 0) {
      navigate("/order-list");
    }
  }, [onSelectedOrderList.length]);

  //TAO HOA DON
  const handleCreateInvoice = async () => {
    setIsLoadingCreateInvoice(true);
    try {
      const result = await CreateInvoiceAPI(
        taxCode,
        token,
        storeDataCreateInvoice
      );
      dispatch(setResponseCreateInvoice(result));
    } catch (err) {
      console.error("Xuất hóa đơn thất bại:", err);
    }
    setIsLoadingCreateInvoice(false);
  };

  const handleBackToHome = () => {
    dispatch(setResponseCreateInvoice(null));
    dispatch(setCurrentPage(1));
    navigate("/order-list");
  };

  return (
    <Card
      className="card flex flex-column justify-content-center gap-2 p-4 text-md"
      title={"Đồng bộ hoá đơn lên m-invoice"}
    >
      <div className="flex flex-column min-h-14rem max-h-30rem mt-4">
        <div className="border-2 border-dashed surface-border border-round surface-ground font-medium p-4 max-h-30rem overflow-auto">
          <ConfirmCreateInvoice />
        </div>
      </div>
      <div className="flex pt-4 justify-content-end align-items-center w-full">
        <Button
          disabled={
            storeDataCreateInvoice.length === 0 || isLoadingCreateInvoice
          }
          className="p-2 bg-blue-500 flex justify-content-center align-items-center text-white"
          onClick={
            responseCreateInvoice && responseCreateInvoice?.code === 200
              ? handleBackToHome
              : handleCreateInvoice
          }
        >
          {isLoadingCreateInvoice ? (
            <div>
              <Loading />
            </div>
          ) : (
            <p className="py-1">
              {responseCreateInvoice && responseCreateInvoice?.code === 200
                ? "Quay lại danh sách hoá đơn"
                : "Xác nhận xuất hoá đơn"}
            </p>
          )}
        </Button>
      </div>
    </Card>
  );
};

export default CreateInvoice;
