// src/features/authSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: null,
  taxCode: "",
  loginStatus: false,
  companyInfo: null,
  symbolList: [],
  selectedSymbol: null,
  onSelectedOrderList: [],
  storeDataCreateInvoice: [],
  responseCreateInvoice: null,
  cookie: null,
  userInfo: null,
  remainingTimeToken: null,
  isAutoSyncCreateInvoice: false,
};

const createInvoiceSlice = createSlice({
  name: "createInvoice",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setTaxCode: (state, action) => {
      state.taxCode = action.payload;
    },
    setLoginStatus: (state, action) => {
      state.loginStatus = action.payload;
    },
    setCompanyInfo: (state, action) => {
      state.companyInfo = action.payload;
    },
    setSymbolList: (state, action) => {
      state.symbolList = action.payload;
    },
    setSelectedSymbol: (state, action) => {
      state.selectedSymbol = action.payload;
    },
    setOnSelectedOrderList: (state, action) => {
      state.onSelectedOrderList = action.payload;
    },
    setStoreDataCreateInvoice: (state, action) => {
      state.storeDataCreateInvoice = action.payload;
    },
    setResponseCreateInvoice: (state, action) => {
      state.responseCreateInvoice = action.payload;
    },
    setCookie: (state, action) => {
      state.cookie = action.payload;
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setRemainingTimeToken: (state, action) => {
      state.remainingTimeToken = action.payload;
    },
    setIsAutoSyncCreateInvoices: (state, action) => {
      state.isAutoSyncCreateInvoice = action.payload;
    },
    clearAuth: (state) => {
      state.token = null;
      state.taxCode = "";
      state.loginStatus = false;
      state.companyInfo = null;
    },
    clearAuthKiotviet: (state) => {
      state.cookie = null;
      state.userInfo = null;
      state.remainingTimeToken = null;
    },
    clearSelectedOrderList: (state) => {
      state.symbolList = [];
      state.selectedSymbol = null;
      state.onSelectedOrderList = [];
      state.storeDataCreateInvoice = [];
      state.responseCreateInvoice = null;
    },
  },
});

export const {
  setToken,
  setTaxCode,
  clearAuth,
  setLoginStatus,
  setCompanyInfo,
  setSymbolList,
  setSelectedSymbol,
  setOnSelectedOrderList,
  clearSelectedOrderList,
  setStoreDataCreateInvoice,
  setResponseCreateInvoice,
  setCookie,
  setUserInfo,
  setRemainingTimeToken,
  clearAuthKiotviet,
  setIsAutoSyncCreateInvoices,
} = createInvoiceSlice.actions;
export default createInvoiceSlice.reducer;
