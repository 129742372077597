// src/features/authSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orderList: [],
  totalRecords: 0,
  invoiceList: [],
  itemPerPage: 20,
  currentPage: 1,
  isLoadingFilter: false,
};

const getOrderListSlice = createSlice({
  name: "getOrderList",
  initialState,
  reducers: {
    setOrderList: (state, action) => {
      state.orderList = action.payload;
    },
    setTotalRecords: (state, action) => {
      state.totalRecords = action.payload;
    },
    setInvoiceList: (state, action) => {
      state.invoiceList = action.payload;
    },
    setItemPerPage: (state, action) => {
      state.itemPerPage = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setIsLoadingFilter: (state, action) => {
      state.isLoadingFilter = action.payload;
    },
  },
});

export const {
  setOrderList,
  setTotalRecords,
  setInvoiceList,
  setItemPerPage,
  setCurrentPage,
  setIsLoadingFilter,
} = getOrderListSlice.actions;
export default getOrderListSlice.reducer;
