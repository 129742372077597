// import axios from "axios";
import axiosInstance from "../configs/axios.conf";

const GetOrderList = async (
  cookie,
  fromDate,
  toDate,
  orderId,
  itemPerPage,
  currentPage
) => {
  const data = {
    cookie: cookie,
    fromDate: fromDate,
    toDate: toDate,
    orderId: orderId,
    itemPerPage: itemPerPage,
    currentPage: currentPage,
  };

  try {
    const response = await axiosInstance.post("orders/list", data);

    return response.data;
  } catch (error) {
    console.error("Lỗi khi tải dữ liệu:", error);
    throw error;
  }
};

export default GetOrderList;
