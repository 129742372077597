import axiosInstance from "../configs/axios.conf";

const getListInvoiceFromMInvoice = async (request) => {
  const data = {
    tuNgay: request.fromDate,
    denngay: request.toDate,
    khieu: request.khieu,
    start: 0,
    count: 500,
    coChiTiet: true,
    token: request.token,
    taxCode: request.taxCode,
  };

  try {
    const response = await axiosInstance.post(
      "m-invoice/get-invoice-list",
      data
    );
    return response.data;
  } catch (error) {
    console.error("Lỗi khi tải dữ liệu:", error);
    throw error;
  }
};

export default getListInvoiceFromMInvoice;
