/* eslint-disable jsx-a11y/alt-text */
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  clearAuth,
  clearAuthKiotviet,
  clearSelectedOrderList,
} from "../../redux/createInvoiceSlice";
import "./NavBar.css";

const NavBar = () => {
  // const [isModalMenu, setIsModalMenu] = useState(false);
  // const [isModalChangePass, setIsModalChangePass] = useState(false);
  const getLocation = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useState(() => {});

  const [showMenu, setShowMenu] = useState(false);
  const toggle = () => {
    setShowMenu(!showMenu);
  };

  const handleLogout = () => {
    localStorage.removeItem("cookie-kiotviet");
    // localStorage.removeItem("companyInfo");
    // localStorage.removeItem("tokenMInvoice");
    // localStorage.removeItem("taxCode");
    // localStorage.removeItem("companyName");
    // localStorage.removeItem("selectedSymbol");
    // dispatch(clearAuth());
    dispatch(clearSelectedOrderList());
    dispatch(clearAuthKiotviet());
    navigate("/");
  };

  const handleToCreateInvoice = () => {
    const companyInfo = localStorage.getItem("companyInfo");
    if (companyInfo) {
      navigate("/create-invoices");
    } else {
      navigate("/cau-hinh-tai-khoan");
    }
  };

  return (
    <div
      // layout navbar
      className="flex justify-content-between align-items-center bg-white shadow-md fixed top-0 w-full px-2 gap-4"
      style={{ zIndex: 1000 }}
    >
      <div>
        <img
          onClick={() => navigate("/order-list")}
          src={require("../../assets/logo-minvoice.png")}
          className="w-4rem cursor-pointer"
        ></img>
      </div>

      {/* navbar list */}
      <div
        // style={{ flex: 6 }}
        className="py-4"
      >
        <div className="border-1 border-gray-200 border-round-md bg-gray-100 px-4 w-auto">
          <ul className="list-none flex gap-2 align-item-center justify-content-center">
            <li
              role="none"
              className={
                getLocation.pathname === "/create-invoices"
                  ? "bg-blue-100 font-semibold border-round-md p-2 hover:bg-gray-200 hover:border-round-xl hover:border-1"
                  : "p-2 hover:bg-gray-200 hover:border-round-xl hover:border-1"
              }
            >
              <div
                className="link cursor-pointer"
                onClick={handleToCreateInvoice}
              >
                <i
                  className="fa fa-refresh"
                  style={{ padding: " 0 0.4rem", color: "#2475BA" }}
                ></i>
                <span className="text-xs font-semibold">
                  Đồng bộ hoá đơn lên m-invoice
                </span>
              </div>
            </li>
            <li
              role="none"
              className={
                getLocation.pathname === "/tao-hoa-don-tu-dong"
                  ? "bg-blue-100 font-semibold border-round-md p-2 hover:bg-gray-200 hover:border-round-xl hover:border-1 cursor-pointer"
                  : "p-2 hover:bg-gray-200 hover:border-round-xl hover:border-1 cursor-pointer"
              }
            >
              {/* <i
                className="fa fa-cogs"
                style={{ padding: " 0 0.4rem", color: "#2475BA" }}
              ></i> */}
              <div className="flex align-items-center gap-2">
                <img
                  src="/system.png"
                  alt="icon auto"
                  style={{ width: "20px", height: "20px" }}
                />
                <Link className="link" to="/tao-hoa-don-tu-dong">
                  <span className="text-xs font-semibold">
                    Xuất hoá đơn tự động
                  </span>
                </Link>
              </div>
            </li>
            <li
              role="none"
              className={
                getLocation.pathname === "/cau-hinh-tai-khoan"
                  ? "bg-blue-100 font-semibold border-round-md p-2 hover:bg-gray-200 hover:border-round-xl hover:border-1 cursor-pointer"
                  : "p-2 hover:bg-gray-200 hover:border-round-xl hover:border-1 cursor-pointer"
              }
            >
              <i
                className="fa fa-cogs"
                style={{ padding: " 0 0.4rem", color: "#2475BA" }}
              ></i>
              <Link className="link" to="/cau-hinh-tai-khoan">
                <span className="text-xs font-semibold">
                  Cấu hình tài khoản
                </span>
              </Link>
            </li>
            <li
              role="none"
              style={{ alignItems: "center" }}
              className={
                getLocation.pathname === "/huong-dan-su-dung"
                  ? "bg-blue-100 font-semibold border-round-md p-2 hover:bg-gray-200 hover:border-round-xl hover:border-1"
                  : "p-2 hover:bg-gray-200 hover:border-round-xl hover:border-1"
              }
            >
              <Link className="link" to="/huong-dan-su-dung">
                <span
                  style={{ paddingRight: "5px" }}
                  className="fa-solid fa-book"
                ></span>
                <span className="text-xs">Hướng dẫn sử dụng</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div
        className="p-2 bg-gray-100 border-circle cursor-pointer"
        onClick={() => toggle()}
      >
        <PermIdentityOutlinedIcon className="icon" />
      </div>
      {showMenu && (
        <div className="dropdown-menu">
          {/* <div className="menu-item">
            <PermIdentityOutlinedIcon />
            <span>Admin</span>
          </div> */}
          {/* <div className="menu-item">
            <VpnKeyOutlinedIcon />
            <span>Hướng dẫn</span>
          </div> */}
          <div className="menu-item" onClick={handleLogout}>
            <LogoutOutlinedIcon />
            <span>Đăng xuất</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default NavBar;
