import { Accordion, AccordionTab } from "primereact/accordion";
import React from "react";
import { Link, useLocation } from "react-router-dom";

export default function Outline() {
  const getLocation = useLocation();
  const customHeader = (title) => {
    return <div style={{ padding: "10px 20px" }}>{title}</div>;
  };
  return (
    <div className="card p-2">
      {getLocation.pathname === "/huong-dan" && (
        <div className="p-2 hover:bg-blue-700 hover:border-round-md hover:border-1 bg-blue-500 m-2 border-round-md cursor-pointer flex justify-content-start w-2">
          <Link className="link" to="/">
            <div className="flex justify-content-start align-items-center">
              <span
                style={{ paddingRight: "5px", color: "white" }}
                className="pi pi-arrow-left"
              ></span>
              <span className="text-sm font-semibold text-white">
                Quay lại trang đăng nhập
              </span>
            </div>
          </Link>
        </div>
      )}
      <Accordion multiple activeIndex={[0]}>
        <AccordionTab headerTemplate={customHeader("Hướng dẫn lấy cookie")}>
          <div className="p-3">
            <p className="m-0 p-2 font-semibold ">
              - Tại màn hình trang chủ của Kiotviet, Nhấn phím F12 hoặc tổ hợp
              Fn + F12, sau đó load lại trang
            </p>
            <p className="m-0 p-2 font-semibold ">1. Chọn tab network</p>
            <p className="m-0 p-2 font-semibold ">
              2. Chọn item chứa thông tin cookie
            </p>
            <p className="m-0 p-2 font-semibold ">
              3. Copy giá trị của Cookie dán vào form đăng nhập của tool
              M-invoice
            </p>

            <img
              src={require("../assets/HD1.png")}
              alt="Hướng dẫn"
              style={{
                maxWidth: "100%",
                height: "auto",
                margin: "20px 0",
                alignItems: "center",
              }}
            />
            <img
              src={require("../assets/HD2.png")}
              alt="Hướng dẫn"
              style={{
                maxWidth: "100%",
                height: "auto",
                margin: "20px 0",
                alignItems: "center",
              }}
            />
          </div>
        </AccordionTab>
        <AccordionTab
          headerTemplate={customHeader(
            "Hướng dẫn đồng bộ hoá đơn lên m-invoice"
          )}
        >
          <div className="p-3">
            <div>
              <p className="m-0 p-2 text-xl font-bold text-blue-500 ">
                A. Màn hình cấu hình tài khoản M-invoice
              </p>
              <p className="m-0 p-2 font-semibold ">
                1. Nhập thông tin tài khoản M-invoice và thực hiện đăng nhập
              </p>
              <img
                src={require("../assets/cau-hinh-thong-tin-dang-nhap.png")}
                alt="Hướng dẫn"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  margin: "20px 0",
                  alignItems: "center",
                }}
              />
              <p className="m-0 p-2 font-semibold ">
                2. Màn hình hiển thị thông tin tài khoản đã đăng nhập thành công
                và chọn kí hiệu hoá đơn
              </p>

              <img
                src={require("../assets/hien-thi-thong-tin-tk.png")}
                alt="Hướng dẫn"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  margin: "20px 0",
                  alignItems: "center",
                }}
              />
            </div>
            <p className="m-0 p-2 text-xl font-bold text-blue-500 ">
              B. Màn hình danh sách đơn hàng của Kiotviet
            </p>
            <p className="m-0 p-2 font-semibold ">
              1. Tích chọn một vài hoặc tất cả đơn hàng cần đồng bộ
            </p>
            <p className="m-0 p-2 font-semibold ">
              2. Nhấn button đồng bộ hoá đơn
            </p>
            <img
              src={require("../assets/chon-hoa-don-dong-bo.png")}
              alt="Hướng dẫn"
              style={{
                maxWidth: "100%",
                height: "auto",
                margin: "20px 0",
                alignItems: "center",
              }}
            />
            <div>
              <p className="m-0 p-2 text-xl font-bold text-blue-500 ">
                C. Màn hình xác nhận thông tin đơn hàng và thực hiện xuất hoá
                đơn
              </p>
              <img
                src={require("../assets/xac-nhan-tao-hoa-don.png")}
                alt="Hướng dẫn"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  margin: "20px 0",
                  alignItems: "center",
                }}
              />
              <p className="m-0 p-2 text-xl font-bold text-blue-500 ">
                D. Trạng thái đồng bộ hoá đơn sẽ được cập nhật sau khi hoàn tất
              </p>
              <img
                src={require("../assets/tao-hoa-don.png")}
                alt="Hướng dẫn"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  margin: "20px 0",
                  alignItems: "center",
                }}
              />
            </div>
            {/* <div
              style={{
                display: "flex",

                width: "100%",
                flexDirection: "row",
              }}
            >
              <img
                src={require("../assets/tao-hoa-don.png")}
                alt="Hướng dẫn"
                style={{
                  maxWidth: "33.3333%",
                  height: "auto",
                  margin: "20px 0",
                  alignItems: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                }}
              />{" "}
              <img
                src={require("../assets/HD5.png")}
                alt="Hướng dẫn"
                style={{
                  maxWidth: "33.3333%",
                  height: "auto",
                  margin: "20px 0",
                  alignItems: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                }}
              />{" "}
              <img
                src={require("../assets/HD6.png")}
                alt="Hướng dẫn"
                style={{
                  maxWidth: "33.3333%",
                  height: "auto",
                  margin: "20px 0",
                  alignItems: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                }}
              />
            </div> */}
          </div>
        </AccordionTab>
        {/* <AccordionTab headerTemplate={customHeader("Video hướng dẫn chi tiết")}>
        <div className="p-3">
          <p className="m-0 p-2">Tạm thời chưa có</p>
          </div>
        </AccordionTab> */}
      </Accordion>
    </div>
  );
}
