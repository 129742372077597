// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* HTML: <div class="loader"></div> */
.loader {
    width: 20px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: 
      radial-gradient(farthest-side,#ffffff 94%,#0000) top/8px 8px no-repeat,
      conic-gradient(#0000 30%,#ffffff);
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 3px),#000 0);
    animation: l13 1s infinite linear;
    padding:5px 0px
  }
  @keyframes l13{ 
    100%{ transform: rotate(1turn); -webkit-transform: rotate(1turn); -moz-transform: rotate(1turn); -ms-transform: rotate(1turn); -o-transform: rotate(1turn); }
  }

  /* HTML: <div class="loader"></div> */
.loader-2 {
  width: 40px;
  --b: 8px; 
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 1px;
  background: conic-gradient(#0000 10%,#719af1) content-box;
  -webkit-mask:
    repeating-conic-gradient(#0000 0deg,#000 1deg 20deg,#0000 21deg 36deg),
    radial-gradient(farthest-side,#0000 calc(100% - 5px - 1px),#000 calc(100% - 5px));
  mask-composite: intersect;
  animation:l4 1s infinite steps(10);
}
@keyframes l4 {to{transform: rotate(1turn)}}`, "",{"version":3,"sources":["webpack://./src/components/Loading/loading.css"],"names":[],"mappings":"AAAA,qCAAqC;AACrC;IACI,WAAW;IACX,eAAe;IACf,kBAAkB;IAClB;;uCAEmC;IACnC,0EAA0E;IAC1E,iCAAiC;IACjC;EACF;EACA;IACE,MAAM,wBAAwB,EAAE,gCAAgC,EAAE,6BAA6B,EAAE,4BAA4B,EAAE,2BAA2B,EAAE;EAC9J;;EAEA,qCAAqC;AACvC;EACE,WAAW;EACX,QAAQ;EACR,eAAe;EACf,kBAAkB;EAClB,YAAY;EACZ,yDAAyD;EACzD;;qFAEmF;EAE3E,yBAAyB;EACjC,kCAAkC;AACpC;AACA,eAAe,GAAG,wBAAwB,CAAC","sourcesContent":["/* HTML: <div class=\"loader\"></div> */\n.loader {\n    width: 20px;\n    aspect-ratio: 1;\n    border-radius: 50%;\n    background: \n      radial-gradient(farthest-side,#ffffff 94%,#0000) top/8px 8px no-repeat,\n      conic-gradient(#0000 30%,#ffffff);\n    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 3px),#000 0);\n    animation: l13 1s infinite linear;\n    padding:5px 0px\n  }\n  @keyframes l13{ \n    100%{ transform: rotate(1turn); -webkit-transform: rotate(1turn); -moz-transform: rotate(1turn); -ms-transform: rotate(1turn); -o-transform: rotate(1turn); }\n  }\n\n  /* HTML: <div class=\"loader\"></div> */\n.loader-2 {\n  width: 40px;\n  --b: 8px; \n  aspect-ratio: 1;\n  border-radius: 50%;\n  padding: 1px;\n  background: conic-gradient(#0000 10%,#719af1) content-box;\n  -webkit-mask:\n    repeating-conic-gradient(#0000 0deg,#000 1deg 20deg,#0000 21deg 36deg),\n    radial-gradient(farthest-side,#0000 calc(100% - 5px - 1px),#000 calc(100% - 5px));\n  -webkit-mask-composite: destination-in;\n          mask-composite: intersect;\n  animation:l4 1s infinite steps(10);\n}\n@keyframes l4 {to{transform: rotate(1turn)}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
