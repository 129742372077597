import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  setCookie,
  setStoreDataCreateInvoice,
} from "../../redux/createInvoiceSlice";
import "../../style/OrderList.scss";
import GetDetailOrder from "../../utils/getDetailOrder";
import LoadingType2 from "../Loading/LoadingType2";
import ToastNotify from "../ToastNotify/ToastNotify";
import { styleError } from "../ToastNotify/ToastNotifyStyle";

const ConfirmCreateInvoice = () => {
  const onSelectedOrderList = useSelector(
    (state) => state.createInvoice.onSelectedOrderList
  );

  const [dataCreateInvoice, setDataCreateInvoice] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedSymbol = useSelector(
    (state) => state.createInvoice.selectedSymbol
  );
  const responseCreateInvoice = useSelector(
    (state) => state.createInvoice.responseCreateInvoice
  );

  const cookieLocal = localStorage.getItem("cookie-kiotviet");
  const cookie = useSelector((state) => state.createInvoice.cookie);
  const [isLoadingCreateDataInvoices, setIsLoadingCreateDataInvoices] =
    useState(false);

  useEffect(() => {
    if (cookieLocal) {
      const token = cookieLocal.match(/ss-tok=([^;]+)/)[1];
      const decoded = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);
      if (decoded.exp > currentTime) {
        dispatch(setCookie(cookieLocal));
      } else {
        toast.error(
          <ToastNotify status={-1} message={"Phiên đăng nhập hết hạn"} />,
          { style: styleError }
        );
        navigate("/");
      }
    } else {
      navigate("/");
    }
  }, [cookieLocal]);
  // cập nhật dữ liệu vào store
  useEffect(() => {
    if (dataCreateInvoice.length > 0) {
      dispatch(setStoreDataCreateInvoice(dataCreateInvoice));
    }
  }, [dataCreateInvoice.length]);

  const getDetailOrder = async (orderItem) => {
    setIsLoadingCreateDataInvoices(true);
    try {
      const orderDetail = await GetDetailOrder(orderItem.Id, cookie);
      if (!orderDetail) {
        toast.error(
          <ToastNotify
            status={-1}
            message={"Có lỗi xảy ra! Vui lòng thực hiện chọn lại hoá đơn"}
          />,
          { style: styleError }
        );
      } else {
        // Tạo mảng chi tiết sản phẩm (details)
        const productDetails = orderDetail.content.data.Data.map(
          (product, index) => {
            const ma_thue = product.CategoryTree === "Bia" ? 10 : 8;
            const totalAmountWithoutVat = Number(
              product.SubTotal / (1 + ma_thue / 100)
            );

            const unitPrice =
              (totalAmountWithoutVat + product.Discount) / product.Quantity;

            const vatAmount = product.SubTotal - totalAmountWithoutVat;

            const productItemInfo = {
              tchat: 1,
              stt_rec0: index + 1,
              inv_itemCode: product.Product.Code || "",
              inv_itemName: product.Product.Name || "",
              inv_unitCode: product.Product.Unit || "",
              inv_unitPrice: unitPrice.toFixed(2) || 0,
              inv_quantity: Number(product.Quantity) || 0,
              inv_TotalAmountWithoutVat:
                Math.round(Number(totalAmountWithoutVat)) || 0,
              inv_vatAmount: Math.round(Number(vatAmount)),
              inv_discountPercentage: 0,
              inv_discountAmount: Number(orderItem.Discount) || 0,
              ma_thue: ma_thue || 0,
              inv_TotalAmount: product.SubTotal || 0,
            };

            return productItemInfo;
          }
        );

        const totalVatAmount = productDetails.reduce((total, product) => {
          return total + (product.inv_vatAmount || 0);
        }, 0);
        const totalAmountWithoutVat = productDetails.reduce(
          (total, product) => {
            return total + (product.inv_TotalAmountWithoutVat || 0);
          },
          0
        );

        const orderInfo = {
          inv_invoiceSeries: selectedSymbol,
          inv_invoiceIssuedDate: orderItem.CreatedDate.split("T")[0],
          inv_currencyCode: "VND",
          inv_exchangeRate: 1,
          so_benh_an: orderItem.Code,
          inv_buyerDisplayName:
            orderItem.CustomerName || "Khách lẻ không lấy hoá đơn",
          inv_buyerLegalName: orderItem.CustomerName || "",
          inv_buyerTaxCode: "",
          inv_buyerAddressLine:
            orderItem.CustomerAddress || "Khách lẻ không có thông tin địa chỉ",
          inv_buyerEmail: orderItem.CustomerEmail || "",
          inv_buyerBankAccount: "",
          inv_buyerBankName: "",
          inv_paymentMethodName: "TM/CK",
          inv_discountAmount: Number(orderItem.Discount) || 0,
          inv_TotalAmountWithoutVat: Math.round(totalAmountWithoutVat) || 0,
          inv_vatAmount: Math.round(totalVatAmount) || 0,
          inv_TotalAmount: Number(orderItem.TotalPayment) || 0,
          key_api: orderItem.Id + orderItem.Code,
        };

        // Gộp thông tin order và details vào một object duy nhất
        const orderWithDetails = {
          ...orderInfo, // Thông tin hóa đơn
          details: [{ tab_id: orderItem.Code, data: productDetails }], // Mảng chi tiết sản phẩm
        };

        setDataCreateInvoice((prev) => {
          const isExitInvoice = prev?.some(
            (item) => item.data[0].key_api === orderWithDetails.key_api
          );
          if (isExitInvoice) {
            return prev;
          }
          const updateData = [
            ...prev,
            { editmode: 1, data: [orderWithDetails] },
          ];
          return updateData;
        });
      }
    } catch (error) {
      console.error("Lỗi tải dữ liệu: ", error);
    }
    setIsLoadingCreateDataInvoices(false);
  };
  useEffect(() => {
    if (onSelectedOrderList && onSelectedOrderList.length > 0) {
      onSelectedOrderList.forEach((order) => {
        getDetailOrder(order);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSelectedOrderList]);

  return (
    <div className="w-full h-full">
      <div>
        <h3 className="text-base">Xác nhận hóa đơn</h3>
        {isLoadingCreateDataInvoices ? (
          <div className="flex justify-content-center">
            <LoadingType2 />
          </div>
        ) : (
          <div className="p-2 flex flex-wrap gap-2">
            {dataCreateInvoice.map((order, index) => {
              return (
                <div
                  key={index}
                  className="p-2 border border-gray-200 mb-2 flex algin-items-start gap-2 w-5"
                >
                  <p className="text-sm">{index + 1}.</p>
                  <div className="flex flex-column gap-2">
                    <h3 className="text-sm font-semibold">
                      Hóa đơn số: {order.data[0].so_benh_an}
                    </h3>
                    <div className="flex justify-between">
                      <div>
                        <p className="text-sm">
                          Ngày tạo: {order.data[0].inv_invoiceIssuedDate}
                        </p>
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div>
                        <p className="text-sm">
                          Kí hiệu hoá đơn: {order.data[0].inv_invoiceSeries}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      {responseCreateInvoice &&
        responseCreateInvoice?.content?.data?.length > 0 && (
          <div>
            <p className="mb-2">Trạng thái:</p>
            <div className="flex flex-col gap-2 flex-wrap">
              {responseCreateInvoice?.content?.data?.map((item, index) => (
                <div
                  className="p-2 border-1 border-gray-500 mb-2 flex algin-items-start gap-1 w-5 rounded-md"
                  key={index}
                >
                  <p className="text-sm">
                    {item?.code === "00"
                      ? item.data?.so_benh_an
                      : item.OrderCode}
                    :
                  </p>
                  <div className="flex flex-column gap-2 align-items-center">
                    <h3
                      className={`text-sm font-semibold ${
                        item?.code === "00" ? "text-green-600" : "text-red-400"
                      }`}
                    >
                      {item?.code === "00"
                        ? "Xuất hóa đơn thành công"
                        : item?.error.code === "88"
                        ? "Hoá đơn đã tồn tại"
                        : item?.error.code === "296"
                        ? "Đơn hàng quá hạn để xuất hoá đơn"
                        : "Xuất hóa đơn thất bại"}
                    </h3>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      <ToastContainer
        autoClose={2000}
        hideProgressBar
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default ConfirmCreateInvoice;
