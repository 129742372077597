import axiosInstance from "../configs/axios.conf";

const GetSymbolList = async (taxCode, token) => {
  const data = {
    taxCode: taxCode,
    token: token,
  };
  try {
    const response = await axiosInstance.post("/m-invoice/symbol-list", data);

    return response.data;
  } catch (error) {
    console.error("Lỗi khi đăng nhập:", error);
    throw error;
  }
};

export default GetSymbolList;
