// import axios from "axios";
import axiosInstance from "../configs/axios.conf";

const GetDetailOrder = async (orderId, cookie) => {
  const data = {
    cookie: cookie,
  };

  try {
    const response = await axiosInstance.post(`orders/detail/${orderId}`, data);

    return response.data;
  } catch (error) {
    console.error("Lỗi khi tải dữ liệu:", error);
    throw error;
  }
};

export default GetDetailOrder;
