// src/app/store.js
import { configureStore } from "@reduxjs/toolkit";
import createInvoiceSlice from "./redux/createInvoiceSlice";
import getOrderListSlice from "./redux/getOrderListSlice";

const store = configureStore({
  reducer: {
    createInvoice: createInvoiceSlice,
    getOrderList: getOrderListSlice,
  },
});

export default store;
