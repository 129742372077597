import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Paginator } from "primereact/paginator";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FilterOrder from "../components/Filter/FilterOrder";
import {
  setOnSelectedOrderList,
  setResponseCreateInvoice,
  setStoreDataCreateInvoice,
} from "../redux/createInvoiceSlice";
import { setCurrentPage, setItemPerPage } from "../redux/getOrderListSlice";
import "../style/OrderList.scss";
import { formatDateTime } from "../utils/formatDate";
import AutomaticSync from "../components/AutomaticSync/AutomaticSync";
import { Button } from "primereact/button";

const dropdownOptions = [
  { label: 5, value: 5 },
  { label: 20, value: 20 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
];

const OrderList = () => {
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(20);
  const [selectedOrders, setSelectedOrders] = useState([]); // Array to store selected orders
  const [selectAll, setSelectAll] = useState(false);
  const orderList = useSelector((state) => state.getOrderList.orderList);
  const totalRecords = useSelector((state) => state.getOrderList.totalRecords);
  const currentPage = useSelector((state) => state.getOrderList.currentPage);
  const isLoadingFilter = useSelector(
    (state) => state.getOrderList.isLoadingFilter
  );
  const [isRefresh, setIsRefresh] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setOnSelectedOrderList([]));
    dispatch(setStoreDataCreateInvoice([]));
    dispatch(setResponseCreateInvoice(null));
    dispatch(setItemPerPage(rows));
    dispatch(setCurrentPage(currentPage));
  }, []);

  const indexBodyTemplate = (rowData, { rowIndex }) =>
    (currentPage - 1) * rows + rowIndex + 1;

  // CHECK BOX
  const onOrderSelect = (e, data) => {
    let selected = [...selectedOrders];
    if (e.checked) {
      selected.push(data);
    } else {
      selected = selected.filter((order) => order.Id !== data.Id);
    }
    setSelectedOrders(selected);
  };

  const onSelectAllChange = (e) => {
    setSelectAll(e.checked);
    if (e.checked) {
      setSelectedOrders(orderList);
    } else {
      setSelectedOrders([]);
    }
  };

  const checkBoxTemplate = (rowData) => {
    return (
      <Checkbox
        inputId={rowData.Id}
        checked={selectedOrders.some((order) => order.Id === rowData.Id)}
        onChange={(e) => onOrderSelect(e, rowData)}
      />
    );
  };

  useEffect(() => {
    dispatch(setOnSelectedOrderList(selectedOrders));
  }, [selectedOrders]);

  const handleChangeRows = (e) => {
    setRows(e.value);
    dispatch(setCurrentPage(1));
    setFirst(1);
    dispatch(setItemPerPage(e.value));
  };

  const onPageChange = async (e) => {
    setFirst(e.first);
    const page = e.first / e.rows + 1;
    dispatch(setCurrentPage(page));
  };

  return (
    <div className="body pb-6">
      <div className="flex justify-content-start align-items-start">
        <div className="px-4 py-2">
          <Button
            type="button"
            className="py-2 px-3 text-sm border-1 border-gray-400 text-gray-600 bg-gray-100"
            icon="pi pi-refresh"
            onClick={() => setIsRefresh(!isRefresh)}
            loading={isLoadingFilter}
          >
            Tải dữ liệu
          </Button>
        </div>
        <div className="w-10">
          <FilterOrder isRefresh={isRefresh} />
        </div>
      </div>
      {/* <div className="mx-4">
        <AutomaticSync />
      </div> */}

      {selectedOrders.length > 0 && (
        <div className="border-2 w-10rem ml-2 border-gray-300 border-round-md p-1">
          <div className="flex justify-content-center align-item-center gap-2">
            <p className="text-xs text-blue-700">
              Đồng bộ {selectedOrders.length} đơn hàng
            </p>
          </div>
        </div>
      )}

      <div className="order-list-container">
        <DataTable
          value={orderList}
          scrollable
          className="data-table-custom"
          rows={rows}
          loading={isLoadingFilter}
          scrollHeight="flex"
          emptyMessage="No orders found"
          rowClassName={(rowData) => {
            return rowData.isCreatedInvoice ? "bg-blue-300 text-white" : "";
          }}
          stripedRows
        >
          <Column
            // selectionMode="multiple"
            header={
              <Checkbox onChange={onSelectAllChange} checked={selectAll} />
            }
            body={checkBoxTemplate}
          />
          <Column
            header="STT"
            body={indexBodyTemplate}
            // sortable
          />
          <Column
            field="isCreatedInvoice"
            header="Xuất hoá đơn"
            // sortable
            body={(rowData) => (
              <div>
                {rowData.isCreatedInvoice ? (
                  <p className="text-blue-900 text-xs font-semibold">Đã xuất</p>
                ) : (
                  <p className="text-black-500 text-xs font-semibold">
                    Chưa xuất
                  </p>
                )}
              </div>
            )}
          />
          <Column field="Id" header="Id" />
          <Column
            field="Code"
            header="Mã"
            className="code-column text-center"
            // headerClassName="flex flex-column justify-content-center align-items-center h-full"
            // sortable
          />
          {/* <Column
            field="OrderId"
            header="OrderId"
            // className="code-column"
            sortable
          /> */}
          <Column
            header="Ngày tạo"
            sortable
            body={(rowData) => formatDateTime(rowData.PurchaseDate)}
          />
          {/* <Column field="BranchId" header="BranchId" sortable /> */}
          {/* <Column field="RetailerId" header="RetailerId" sortable /> */}
          <Column
            field="Discount"
            header="Giảm giá"
            // sortable
            body={(rowData) =>
              new Intl.NumberFormat("vi-VN", {
                style: "currency",
                currency: "VND",
              }).format(rowData.Discount)
            }
          />
          {/* <Column field="SoldById" header="SoldById" sortable /> */}
          {/* <Column field="TableId" header="TableID" sortable /> */}
          {/* <Column
            header="CreatedDate"
            sortable
            body={(rowData) => formatDateTime(rowData.CreatedDate)}
          /> */}
          {/* <Column field="CreatedBy" header="CreatedBy" sortable /> */}
          <Column
            field="Total"
            header="Tổng"
            // sortable
            body={(rowData) =>
              new Intl.NumberFormat("vi-VN", {
                style: "currency",
                currency: "VND",
              }).format(rowData.Total)
            }
          />
          <Column
            field="TotalPayment"
            header="Thanh toán"
            // sortable
            body={(rowData) =>
              new Intl.NumberFormat("vi-VN", {
                style: "currency",
                currency: "VND",
              }).format(rowData.TotalPayment)
            }
          />
          <Column
            field="PayingAmount"
            header="Số tiền thanh toán"
            // sortable
            body={(rowData) =>
              new Intl.NumberFormat("vi-VN", {
                style: "currency",
                currency: "VND",
              }).format(rowData.PayingAmount)
            }
          />
          <Column field="StatusValue" header="Trạng thái" />
          {/* <Column field="" header="View more" body={viewMoreButton} sortable /> */}
        </DataTable>
        <div
          className="flex justify-content-center align-items-center"
          style={{ background: "#f9f9f9" }}
        >
          <Paginator
            first={first}
            rows={rows}
            totalRecords={totalRecords}
            onPageChange={onPageChange}
          />
          <Dropdown
            value={rows}
            options={dropdownOptions}
            onChange={handleChangeRows}
            className="flex justify-content-center align-items-center pl-3 pr-0 py-3"
          />
        </div>
      </div>
    </div>
  );
};

export default OrderList;
