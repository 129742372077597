import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import StartAutoCreateInvoice from "../utils/StartAutoCreateInvoice";
import CancelAutoCreateInvoice from "../utils/CancelAutoCreateInvoice";
import ToastNotify from "../components/ToastNotify/ToastNotify";
import {
  styleError,
  styleSuccess,
} from "../components/ToastNotify/ToastNotifyStyle";
import { Card } from "primereact/card";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading/Loading";
import GetStatusAutoCreateInvoice from "../utils/GetStatusAutoCreateInvoice";

const AutoCreateInvoicePage = () => {
  const [cookie, setCookie] = useState();
  const [taxCode, setTaxCode] = useState();
  const [invoiceSymbol, setInvoiceSymbol] = useState();
  const [isAutoSync, setIsAutoSync] = useState(false);
  console.log("🚀 ~ AutoCreateInvoicePage ~ isAutoSync:", isAutoSync);
  const [
    isLoadingStartAutoCreateInvoices,
    setIsLoadingStartAutoCreateInvoices,
  ] = useState(false);
  const [
    isLoadingCancelAutoCreateInvoices,
    setIsLoadingCancelAutoCreateInvoices,
  ] = useState(false);
  const hasCookie = localStorage.getItem("cookie-kiotviet");
  const hasTaxCode = localStorage.getItem("taxCode");
  const hasInvoiceSymbol = localStorage.getItem("selectedSymbol");

  const navigate = useNavigate();

  useEffect(() => {
    const handleGetStatusAutoCreateInvoices = async () => {
      if (taxCode) {
        try {
          const getStatusAutoCreateInvoice = await GetStatusAutoCreateInvoice(
            taxCode
          );
          console.log(
            "🚀 ~ handleGetStatusAutoCreateInvoices ~ getStatusAutoCreateInvoice:",
            getStatusAutoCreateInvoice
          );

          if (getStatusAutoCreateInvoice.code === 200) {
            setIsAutoSync(getStatusAutoCreateInvoice.content.is_active);
          }
        } catch (error) {
          console.error(error);
        }
      }
    };
    handleGetStatusAutoCreateInvoices();
  }, [isAutoSync, taxCode]);

  useEffect(() => {
    if (hasCookie && hasTaxCode && hasInvoiceSymbol) {
      setCookie(hasCookie);
      setTaxCode(hasTaxCode);
      setInvoiceSymbol(hasInvoiceSymbol);
    } else {
      navigate("/cau-hinh-tai-khoan");
    }
  }, [hasCookie, hasInvoiceSymbol, hasTaxCode]);

  useEffect(() => {
    if (isAutoSync) {
      handleStartAutoCreateInvoice();
    }
  }, [invoiceSymbol, hasInvoiceSymbol, isAutoSync]);

  const handleStartAutoCreateInvoice = async () => {
    if (cookie && taxCode && invoiceSymbol) {
      setIsLoadingStartAutoCreateInvoices(true);
      try {
        const startAutoCreateInvoice = await StartAutoCreateInvoice(
          cookie,
          taxCode,
          invoiceSymbol
        );
        if (startAutoCreateInvoice.code === 200) {
          setIsAutoSync(true);
          localStorage.setItem("isAutoSyncCreateInvoices", true);
          toast.success(
            <ToastNotify
              status={1}
              message={"Bật xuất hoá đơn tự động thành công"}
            />,
            { style: styleSuccess }
          );
        }
      } catch (error) {
        toast.error(
          <ToastNotify
            status={1}
            message={"Bật xuất hoá đơn tự động thất bại"}
          />,
          { style: styleError }
        );
        console.log(error);
      }
    }
    setIsLoadingStartAutoCreateInvoices(false);
  };

  const handleCancelAutoCreateInvoice = async () => {
    if (taxCode && isAutoSync) {
      try {
        setIsLoadingCancelAutoCreateInvoices(true);
        const cancelAutoCreateInvoice = await CancelAutoCreateInvoice(taxCode);
        if (cancelAutoCreateInvoice.code === 200) {
          localStorage.setItem("isAutoSyncCreateInvoices", false);
          setIsAutoSync(false);
          toast.success(
            <ToastNotify
              status={1}
              message={"Huỷ xuất hoá đơn tự động thành công"}
            />,
            { style: styleSuccess }
          );
        }
      } catch (error) {
        toast.error(
          <ToastNotify
            status={1}
            message={"Huỷ xuất hoá đơn tự động thất bại"}
          />,
          { style: styleError }
        );
        console.log(error);
      }
    }
    setIsLoadingCancelAutoCreateInvoices(false);
  };

  return (
    <Card className="flex flex-column gap-4 justify-content-start align-items-start p-4 m-4">
      <div className="mb-4 flex flex-column gap-2">
        <p className="text-2xl">
          Xác nhận {isAutoSync ? "tắt" : "bật"} xuất hoá đơn tự động với kí hiệu
          hoá đơn <span className="text-indigo-500">{invoiceSymbol}</span>
        </p>
        <p className="text-xs">
          Khi tính năng tự động táo hoá đơn được{" "}
          <span className="font-semibold text-sm text-indigo-500">Bật</span> thì
          hệ thống sẽ tự động xuất hoá đơn sau mỗi{" "}
          <span className="font-semibold text-sm text-indigo-500">15 phút</span>{" "}
          và lần cuối cùng xuất tự động trong ngày sẽ vào lúc{" "}
          <span className="font-semibold text-sm text-indigo-500">
            {" "}
            23 giờ 59 phút
          </span>{" "}
          mỗi ngày.
        </p>
      </div>
      {isAutoSync === false ? (
        <Button
          type="button"
          className="py-2 px-3 bg-indigo-500 border-none hover:bg-indigo-500 text-sm"
          onClick={handleStartAutoCreateInvoice}
          loading={isLoadingStartAutoCreateInvoices}
        >
          Xác nhận bật
        </Button>
      ) : (
        <Button
          type="button"
          className="py-2 px-3 bg-red-500 border-none hover:bg-red-600 text-sm"
          onClick={handleCancelAutoCreateInvoice}
          loading={isLoadingCancelAutoCreateInvoices}
        >
          Xác nhận huỷ
        </Button>
      )}
    </Card>
  );
};

export default AutoCreateInvoicePage;
