import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Panel } from "primereact/panel";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setIsAutoSyncCreateInvoices } from "../../redux/createInvoiceSlice";
import CreateInvoiceAPI from "../../utils/CreateInvoice";
import GetOrderList from "../../utils/GetOrder";
import { getDefaultFromDate } from "../../utils/formatDate";
import GetDetailOrder from "../../utils/getDetailOrder";
import getListInvoiceFromMInvoice from "../../utils/getInvoiceListFromMInvoice";
import ToastNotify from "../ToastNotify/ToastNotify";
import { styleError } from "../ToastNotify/ToastNotifyStyle";

const AutomaticSync = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [targetHour, setTargetHour] = useState();
  const dispatch = useDispatch();
  const cookie = useSelector((state) => state.createInvoice.cookie);
  const token = useSelector((state) => state.createInvoice.token);
  const taxCode = useSelector((state) => state.createInvoice.taxCode);
  const selectedSymbol = useSelector(
    (state) => state.createInvoice.selectedSymbol
  );
  const companyInfo = useSelector((state) => state.createInvoice.companyInfo);
  const [responseCreateInvoice, setResponseCreateInvoice] = useState(null);
  const [timeLeft, setTimeLeft] = useState(null);
  const navigate = useNavigate();
  //   const timeToAutoSyncKiotviet = localStorage.getItem("timeToAutoSyncKiotviet");
  const currentDate = new Date();
  const [isSetAutoSync, setIsSetAutoSync] = useState(false);
  const isAutoSyncLocal = localStorage.getItem("isSetAutoSync");
  const timeoutRef = useRef(null);
  const [isCreateInvoice, setIsCreateInvoice] = useState(false);

  useEffect(() => {
    if (JSON.parse(isAutoSyncLocal)) {
      setIsSetAutoSync(JSON.parse(isAutoSyncLocal));
    }
  }, [isAutoSyncLocal]);

  useEffect(() => {
    if (!isSetAutoSync) {
      setTargetHour(null);
      setResponseCreateInvoice(null);
      clearInterval(intervalRef.current);
      setTimeLeft(null);
    }
  }, [isSetAutoSync]);

  //   useEffect(() => {
  //     if (timeToAutoSyncKiotviet && isSetAutoSync) {
  //       if (
  //         new Date(timeToAutoSyncKiotviet).getDate() === currentDate.getDate()
  //       ) {
  //         setTargetHour(new Date(timeToAutoSyncKiotviet));
  //       }

  //       if (new Date(timeToAutoSyncKiotviet).getDate() < currentDate.getDate()) {
  //         let targetTime = currentDate;
  //         targetTime.setDate(new Date().getDate());
  //         targetTime.setHours(new Date(timeToAutoSyncKiotviet).getHours());
  //         targetTime.setMinutes(new Date(timeToAutoSyncKiotviet).getMinutes());
  //         targetTime.setSeconds(new Date(timeToAutoSyncKiotviet).getSeconds());
  //         targetTime.setMilliseconds(
  //           new Date(timeToAutoSyncKiotviet).getSeconds()
  //         );
  //         localStorage.setItem("timeToAutoSyncKiotviet", targetTime);
  //         setTargetHour(targetTime);
  //       }
  //       setResponseCreateInvoice(null);
  //     }
  //   }, [timeToAutoSyncKiotviet, isSetAutoSync]);

  //   useEffect(() => {
  //     if (
  //       cookie &&
  //       token &&
  //       taxCode &&
  //       selectedSymbol &&
  //       companyInfo &&
  //       isSetAutoSync &&
  //       targetHour
  //     ) {
  //       if (!(targetHour instanceof Date)) {
  //         console.error("targetHour must be a Date object");
  //         return;
  //       }
  //       const calculateTimeLeft = () => {
  //         const now = new Date();
  //         const difference = targetHour - now;

  //         if (difference > 0) {
  //           const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
  //           const minutes = Math.floor((difference / 1000 / 60) % 60);
  //           const seconds = Math.floor((difference / 1000) % 60);
  //           setTimeLeft({ hours, minutes, seconds });
  //         } else if (
  //           now.getHours() === targetHour.getHours() &&
  //           now.getMinutes() === targetHour.getMinutes() &&
  //           now.getSeconds() === targetHour.getSeconds()
  //         ) {
  //           setTimeLeft(null);
  //           setResponseCreateInvoice(null);
  //           onTargetTime();
  //           clearInterval(interval); // Dừng interval sau khi thực hiện
  //         } else {
  //           setTimeLeft(null);
  //           clearInterval(interval); // Dừng interval nếu đã qua thời gian mục tiêu
  //         }
  //       };

  //       const interval = setInterval(() => {
  //         calculateTimeLeft();
  //       }, 1000); // Cập nhật mỗi giây

  //       return () => clearInterval(interval); // Dọn dẹp interval khi component unmount
  //     } else {
  //       if (!companyInfo || !selectedSymbol) {
  //         navigate("/cau-hinh-tai-khoan");
  //       }
  //       if (!cookie) {
  //         navigate("/");
  //       }
  //     }
  //   }, [
  //     targetHour,
  //     cookie,
  //     token,
  //     taxCode,
  //     selectedSymbol,
  //     companyInfo,
  //     isSetAutoSync,
  //   ]);

  const handleGetOrderList = async () => {
    try {
      const response = await GetOrderList(
        cookie,
        getDefaultFromDate(),
        getDefaultFromDate(),
        null,
        500,
        1
      );

      if (response.content.data.Data) {
        const orderList = response.content.data.Data.slice(1);
        return orderList;
      }
    } catch (err) {
      console.error("Lỗi tải dữ liệu:", err);
      return 0;
    }
  };

  const handleGetInvoiceList = async () => {
    const data = {
      fromDate: getDefaultFromDate(),
      toDate: getDefaultFromDate(),
      khieu: selectedSymbol,
      token: token,
      taxCode: taxCode,
    };
    try {
      const response = await getListInvoiceFromMInvoice(data);
      if (response.content.data.code === "00") {
        const invoiceList = response.content.data.data;
        return invoiceList;
      }
    } catch (error) {
      console.error("Lỗi khi tải dữ liệu:", error);
      return 0;
    }
  };

  const getDetailOrder = async (orderItem) => {
    try {
      const orderDetail = await GetDetailOrder(orderItem.Id, cookie);
      if (!orderDetail) {
        toast.error(
          <ToastNotify
            status={-1}
            message={"Có lỗi xảy ra! Vui lòng thực hiện chọn lại hoá đơn"}
          />,
          { style: styleError }
        );
      } else {
        // Tạo mảng chi tiết sản phẩm (details)
        const productDetails = orderDetail.content.data.Data.map(
          (product, index) => {
            const ma_thue = product.CategoryTree === "Bia" ? 10 : 8;
            const totalAmountWithoutVat = Number(
              product.SubTotal / (1 + ma_thue / 100)
            );

            const unitPrice =
              (totalAmountWithoutVat + product.Discount) / product.Quantity;

            const vatAmount = product.SubTotal - totalAmountWithoutVat;

            const productItemInfo = {
              tchat: 1,
              stt_rec0: index + 1,
              inv_itemCode: product.Product.Code || "",
              inv_itemName: product.Product.Name || "",
              inv_unitCode: product.Product.Unit || "",
              inv_unitPrice: unitPrice.toFixed(2) || 0,
              inv_quantity: Number(product.Quantity) || 0,
              inv_TotalAmountWithoutVat:
                Math.round(Number(totalAmountWithoutVat)) || 0,
              inv_vatAmount: Math.round(Number(vatAmount)),
              inv_discountPercentage: 0,
              inv_discountAmount: Number(orderItem.Discount) || 0,
              ma_thue: ma_thue || 0,
              inv_TotalAmount: product.SubTotal || 0,
            };

            return productItemInfo;
          }
        );

        const totalVatAmount = productDetails.reduce((total, product) => {
          return total + (product.inv_vatAmount || 0);
        }, 0);
        const totalAmountWithoutVat = productDetails.reduce(
          (total, product) => {
            return total + (product.inv_TotalAmountWithoutVat || 0);
          },
          0
        );

        const orderInfo = {
          inv_invoiceSeries: selectedSymbol,
          inv_invoiceIssuedDate: orderItem.CreatedDate.split("T")[0],
          inv_currencyCode: "VND",
          inv_exchangeRate: 1,
          so_benh_an: orderItem.Code,
          inv_buyerDisplayName:
            orderItem.CustomerName || "Khách lẻ không lấy hoá đơn",
          inv_buyerLegalName: orderItem.CustomerName || "",
          inv_buyerTaxCode: "",
          inv_buyerAddressLine:
            orderItem.CustomerAddress || "Khách lẻ không có thông tin địa chỉ",
          inv_buyerEmail: orderItem.CustomerEmail || "",
          inv_buyerBankAccount: "",
          inv_buyerBankName: "",
          inv_paymentMethodName: "TM/CK",
          inv_discountAmount: Number(orderItem.Discount) || 0,
          inv_TotalAmountWithoutVat: Math.round(totalAmountWithoutVat) || 0,
          inv_vatAmount: Math.round(totalVatAmount) || 0,
          inv_TotalAmount: Number(orderItem.TotalPayment) || 0,
          key_api: orderItem.Id,
        };

        // Gộp thông tin order và details vào một object duy nhất
        const orderWithDetails = {
          ...orderInfo, // Thông tin hóa đơn
          details: [{ tab_id: orderItem.Code, data: productDetails }], // Mảng chi tiết sản phẩm
        };

        const newData = {
          editmode: 1,
          data: [orderWithDetails],
        };
        return newData;
      }
    } catch (error) {
      console.error("Lỗi tải dữ liệu: ", error);
    }
  };

  //TAO HOA DON
  const handleCreateInvoice = async (dataCreateInvoice) => {
    try {
      const result = await CreateInvoiceAPI(taxCode, token, dataCreateInvoice);
      setResponseCreateInvoice(result);
      return true;
    } catch (err) {
      console.error("Xuất hóa đơn thất bại:", err);
    }
  };

  const onTargetTime = async () => {
    const orderInfo = await handleGetOrderList();
    const invoiceInfo = await handleGetInvoiceList();
    if (orderInfo !== 0 && invoiceInfo !== 0) {
      let updateNewOrderList = [...orderInfo];

      const soBenhAnSet = new Set(
        invoiceInfo.map((invoice) => invoice.so_benh_an)
      );

      updateNewOrderList = updateNewOrderList.filter(
        (order) => !soBenhAnSet.has(order.Code)
      );

      if (updateNewOrderList.length > 0) {
        let response = await Promise.all(
          updateNewOrderList.map((order) => getDetailOrder(order))
        );
        if (response.length > 0) {
          await handleCreateInvoice(response);
          updateNewOrderList = [];
          dispatch(setIsAutoSyncCreateInvoices(true));
        } else {
          dispatch(setIsAutoSyncCreateInvoices(false));
          toast.error(
            <ToastNotify
              status={-1}
              message={"Tất cả đơn hàng đã được xuất hoá đơn"}
            />,
            { style: styleError }
          );
        }
      }
    }
  };

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleChangeAutoSyncStatus = () => {
    setIsSetAutoSync(!isSetAutoSync);
    localStorage.setItem("isSetAutoSync", !isSetAutoSync);
  };

  //   const handleSetTimeAutoSync = (time) => {
  //     localStorage.setItem("timeToAutoSyncKiotviet", time);
  //     setTargetHour(time);
  //   };

  // Thêm vào state để lưu khoảng thời gian đã chọn
  const [intervalTime, setIntervalTime] = useState(15);

  // Các tùy chọn khoảng thời gian
  const intervalOptions = [
    { label: "15 phút", value: 15 },
    { label: "30 phút", value: 30 },
    { label: "60 phút", value: 60 },
    { label: "90 phút", value: 90 },
  ];

  // Thêm vào hàm để xử lý thay đổi khoảng thời gian
  const handleIntervalChange = (e) => {
    setIntervalTime(e.value);
    localStorage.setItem("intervalTime", e.value);
  };

  const savedIntervalTime = localStorage.getItem("intervalTime");
  useEffect(() => {
    if (savedIntervalTime) {
      setIntervalTime(Number(savedIntervalTime));
    }
  }, [savedIntervalTime]);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (
      cookie &&
      token &&
      taxCode &&
      selectedSymbol &&
      companyInfo &&
      isSetAutoSync &&
      intervalTime
    ) {
      const startCountdown = () => {
        const now = new Date();
        const targetTime = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          23,
          59,
          0
        );
        const timeRemainingToTarget = targetTime - now;

        if (timeRemainingToTarget > 0) {
          // Nếu thời gian hiện tại là trước 23h59
          if (timeRemainingToTarget <= intervalTime * 60 * 1000) {
            // Nếu thời gian còn lại nhỏ hơn hoặc bằng 15 phút
            const endTime = now.getTime() + timeRemainingToTarget;
            intervalRef.current = setInterval(() => {
              const currentTime = new Date().getTime();
              const distance = endTime - currentTime;

              const hours = Math.floor(
                (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
              );
              const minutes = Math.floor(
                (distance % (1000 * 60 * 60)) / (1000 * 60)
              );
              const seconds = Math.floor((distance % (1000 * 60)) / 1000);

              setTimeLeft({ hours, minutes, seconds });

              if (distance < 0) {
                clearInterval(intervalRef.current);
                onTargetTime().then(() => {
                  startCountdown(); // Bắt đầu lại đếm ngược cho lần chạy tiếp theo
                });
              }
            }, 1000);
          } else {
            // Nếu thời gian còn lại lớn hơn 15 phút
            const endTime = now.getTime() + intervalTime * 60 * 1000; // 15 phút
            intervalRef.current = setInterval(() => {
              const currentTime = new Date().getTime();
              const distance = endTime - currentTime;

              const hours = Math.floor(
                (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
              );
              const minutes = Math.floor(
                (distance % (1000 * 60 * 60)) / (1000 * 60)
              );
              const seconds = Math.floor((distance % (1000 * 60)) / 1000);

              setTimeLeft({ hours, minutes, seconds });

              if (distance < 0) {
                clearInterval(intervalRef.current);
                onTargetTime().then(() => {
                  startCountdown(); // Bắt đầu lại đếm ngược cho lần chạy tiếp theo
                });
              }
            }, 1000);
          }
        } else {
          // Nếu thời gian hiện tại là sau 23h59
          const adjustedIntervalTime = intervalTime; // Khoảng thời gian 15 phút
          const endTime = now.getTime() + adjustedIntervalTime * 60 * 1000;
          intervalRef.current = setInterval(() => {
            const currentTime = new Date().getTime();
            const distance = endTime - currentTime;

            const hours = Math.floor(
              (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            const minutes = Math.floor(
              (distance % (1000 * 60 * 60)) / (1000 * 60)
            );
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            setTimeLeft({ hours, minutes, seconds });

            if (distance < 0) {
              clearInterval(intervalRef.current);
              onTargetTime().then(() => {
                startCountdown(); // Bắt đầu lại đếm ngược cho lần chạy tiếp theo
              });
            }
          }, 1000);
        }
      };

      startCountdown();
      return () => clearInterval(intervalRef.current);
    } else {
      if (!companyInfo || !selectedSymbol) {
        navigate("/cau-hinh-tai-khoan");
      }
      if (!cookie) {
        navigate("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    intervalTime,
    cookie,
    token,
    taxCode,
    selectedSymbol,
    companyInfo,
    isSetAutoSync,
  ]);

  useEffect(() => {
    const savedIntervalTime = localStorage.getItem("intervalTime");
    if (savedIntervalTime) {
      setIntervalTime(Number(savedIntervalTime));
    }
  }, []);

  useEffect(() => {
    if (intervalTime) {
      const now = new Date();
      const endTime = now.getTime() + intervalTime * 60 * 1000;
      const distance = endTime - now.getTime();

      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setTimeLeft({ hours, minutes, seconds });
    }
  }, [intervalTime]);

  useEffect(() => {
    let timer;
    if (responseCreateInvoice) {
      timer = setTimeout(() => {
        setResponseCreateInvoice(null);
      }, 60000); // 1 phút = 60000ms
    }
    return () => clearTimeout(timer);
  }, [responseCreateInvoice]);

  return (
    <div className="py-2">
      <Button
        label="Cấu hình thời gian xuất hoá đơn tự động"
        icon="pi pi-cog"
        onClick={toggleFilter}
        className="p-2 bg-white text-blue-500 text-sm border-1 border-blue-500 hover:bg-blue-100"
      />
      {showFilter && (
        <Panel>
          <div className="p-4 flex flex-column gap-2">
            <div>
              <div className="flex align-items-center">
                <Checkbox
                  inputId="ingredient1"
                  name="pizza"
                  value="Cheese"
                  onChange={handleChangeAutoSyncStatus}
                  checked={isSetAutoSync}
                />
                <label htmlFor="ingredient1" className="ml-2">
                  Xuất hoá đơn tự động
                </label>
              </div>
            </div>
            <div
              className={`justify-content-start align-items-center gap-5 ${
                isSetAutoSync ? "flex" : "hidden"
              }`}
            >
              {/* <div className="p-2 flex gap-2 align-items-center">
                <label htmlFor="fromDate" className="text-sm font-base">
                  Thời gian
                </label>
                <Calendar
                  id="fromDate"
                  value={targetHour}
                  onChange={(e) => handleSetTimeAutoSync(e.value)}
                  showIcon
                  timeOnly
                  className="custom-input"
                />
              </div> */}
              <div className="p-2 flex gap-2 align-items-center">
                <label htmlFor="intervalTime" className="text-sm font-base">
                  Khoảng thời gian
                </label>
                <Dropdown
                  id="intervalTime"
                  value={intervalTime}
                  options={intervalOptions}
                  onChange={handleIntervalChange}
                  placeholder="Chọn khoảng thời gian"
                  className="p-2 text-sm"
                  style={{ fontSize: "12px", color: "red" }} // Kiểu dáng trực tiếp cho giá trị
                />
              </div>
            </div>
            {timeLeft && (
              <div className="flex justify-content-start align-items-center gap-3">
                <p className="text-sm">Thời gian còn lại: </p>
                <p className="text-sm">
                  {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s
                </p>
              </div>
            )}
            {responseCreateInvoice && (
              <div>
                <p className="mb-2">Trạng thái:</p>
                <div className="flex flex-col gap-2 flex-wrap">
                  {responseCreateInvoice?.content?.data?.map((item, index) => (
                    <div
                      className="p-2 border-1 border-gray-500 mb-2 flex algin-items-start gap-1 w-5 rounded-md"
                      key={index}
                    >
                      <p className="text-sm">
                        {item?.code === "00"
                          ? item.data?.so_benh_an
                          : item.OrderCode}
                        :
                      </p>
                      <div className="flex flex-column gap-2">
                        <h3
                          className={`text-sm font-semibold ${
                            item?.code === "00"
                              ? "text-green-500"
                              : "text-red-500"
                          }`}
                        >
                          {item?.code === "00" ? "Thành công" : "Thất bại"}
                        </h3>
                        <p className="text-sm">{item?.message}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </Panel>
      )}
      {/* 
      <Button onClick={onTargetTime}>Test</Button> */}
    </div>
  );
};

export default AutomaticSync;
