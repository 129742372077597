// axiosConfig.js
import axios from "axios";

// Tạo một instance của Axios
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Thay thế bằng URL API của bạn
  timeout: 200000, // Thời gian chờ request (ms)
  headers: {
    "Content-Type": "application/json",
    // Thêm các headers khác nếu cần
  },
});

// Thêm request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Làm gì đó trước khi request được gửi đi
    // Ví dụ: Thêm token vào headers
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Làm gì đó với lỗi request
    return Promise.reject(error);
  }
);

// Thêm response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Làm gì đó với dữ liệu response
    return response;
  },
  (error) => {
    // Làm gì đó với lỗi response
    return Promise.reject(error);
  }
);

export default axiosInstance;
