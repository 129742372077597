import axiosInstance from "../configs/axios.conf";

const StartAutoCreateInvoice = async (cookie, taxCode, symbol) => {
  const data = {
    kiotviet_cookie: cookie,
    tax_code: taxCode,
    khhdon: symbol,
    user_id: taxCode,
  };
  try {
    const response = await axiosInstance.post(
      "/auto-create-invoice/start-auto-create-invoice",
      data
    );

    return response.data;
  } catch (error) {
    console.error("Start error:", error);
    throw error;
  }
};

export default StartAutoCreateInvoice;
