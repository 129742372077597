// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-input input.p-inputtext.p-component {
    padding: 0.4rem 0.5rem;
    font-size: 14px;
}

.custom-icon-size .pi {
    font-size: 0.7rem; /* Thay đổi kích thước font của icon */
  }`, "",{"version":3,"sources":["webpack://./src/components/Filter/filterOrder.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,iBAAiB,EAAE,sCAAsC;EAC3D","sourcesContent":[".custom-input input.p-inputtext.p-component {\n    padding: 0.4rem 0.5rem;\n    font-size: 14px;\n}\n\n.custom-icon-size .pi {\n    font-size: 0.7rem; /* Thay đổi kích thước font của icon */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
