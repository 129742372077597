import { useEffect } from "react";
import NavBar from "../Navbar/Navbar";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import { Outlet, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useDispatch } from "react-redux";
import {
  setCompanyInfo,
  setCookie,
  setSelectedSymbol,
  setTaxCode,
  setToken,
  setUserInfo,
} from "../../redux/createInvoiceSlice";
import { toast } from "react-toastify";
import ToastNotify from "../ToastNotify/ToastNotify";
import { styleError } from "../ToastNotify/ToastNotifyStyle";

const Layout = () => {
  const cookieLocal = localStorage.getItem("cookie-kiotviet");
  const companyInfo = localStorage.getItem("companyInfo");
  const token = localStorage.getItem("tokenMInvoice");
  const taxCode = localStorage.getItem("taxCode");
  const selectedSymbol = localStorage.getItem("selectedSymbol");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (cookieLocal) {
      const token = cookieLocal.match(/ss-tok=([^;]+)/)[1];
      const decoded = jwtDecode(token);
      dispatch(setUserInfo(decoded));
      const currentTime = Math.floor(Date.now() / 1000);
      if (decoded.exp > currentTime) {
        dispatch(setCookie(cookieLocal));
      } else {
        toast.error(
          <ToastNotify status={-1} message={"Phiên đăng nhập hết hạn"} />,
          { style: styleError }
        );
        navigate("/");
      }
    } else {
      navigate("/");
    }
  }, [cookieLocal]);

  useEffect(() => {
    if (companyInfo) {
      dispatch(setCompanyInfo(JSON.parse(companyInfo)));
    }

    if (token) {
      dispatch(setToken(token));
    }

    if (taxCode) {
      dispatch(setTaxCode(taxCode));
    }

    if (selectedSymbol) {
      dispatch(setSelectedSymbol(selectedSymbol));
    }
  }, [companyInfo, token, taxCode, selectedSymbol]);

  return (
    <div className="w-full h-full flex justify-content-center flex-column gap-2">
      <div>
        <NavBar />
      </div>
      <div className="p-2 w-full">
        <Breadcrumbs />
      </div>
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
