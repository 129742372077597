import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "././login.scss";
import "./App.css";
import Layout from "./components/Layout/layout";
import AccountConfig from "./page/AccountConfig";
import CreateInvoice from "./page/CreateInvoice";
import Login from "./page/Login";
import OrderList from "./page/OrderList";
import store from "./store";
import { ToastContainer } from "react-toastify";
import Outline from "./page/Outline";
import AutoCreateInvoicePage from "./page/AutoCreateInvoice";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/huong-dan",
    element: <Outline />,
  },
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/order-list",
        element: <OrderList />,
      },
      {
        path: "/create-invoices",
        element: <CreateInvoice />,
      },
      {
        path: "/tao-hoa-don-tu-dong",
        element: <AutoCreateInvoicePage />,
      },
      {
        path: "/cau-hinh-tai-khoan",
        element: <AccountConfig />,
      },
      {
        path: "/huong-dan-su-dung",
        element: <Outline />,
      },
    ],
  },
]);
function App() {
  return (
    <div className="app">
      <div className="container">
        <PrimeReactProvider>
          <Provider store={store}>
            <RouterProvider router={router} />
          </Provider>
        </PrimeReactProvider>
      </div>
      <ToastContainer
        autoClose={2000}
        hideProgressBar
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
